























// Core
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Interfaces
import { Meta, Paginate, RequestParams } from '@store/interfaces'
import { Project, ProjectsState } from '@store/projects/interfaces'
import { ProjectsActions } from '@store/projects/types'

const NSProjects = namespace('projectsModule')

@Component({
  name: 'ProjectList',

  components: {
    'v-scroll-list': () => import('@components/ScrollList.vue'),
  },
})
export default class ProjectListComponent extends Vue {
  @Prop(Function)
  private handleConnectUserToProject!: (projectId: number) => void

  @NSProjects.State((state: ProjectsState) => state.projectList) private projects!: Project[]

  @NSProjects.State((state: ProjectsState) => state.projects.meta)
  private meta!: Meta

  @NSProjects.Action(ProjectsActions.A_GET_PROJECTS)
  private fetchProjects!: (params?: RequestParams) => Promise<Paginate<Project>>

  private page: number = 1

  private created() {
    if (!this.projects.length) {
      this.fetchProjects({
        page: this.page,
      })
    } else {
      this.page = this.meta.currentPage
    }
  }

  private loadMore() {
    if (this.meta.lastPage > this.page) {
      this.page += 1

      this.fetchProjects({
        page: this.page,
      })
    }
  }
}
